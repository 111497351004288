.link-title{
    display : none ;
}
.collapsed .menu-link {
    background : transparent !important;
    color: black !important;
    box-shadow : none !important;
}
.collapsed .menu-link {
    color : black  !important;
    padding-left: 2px !important;
}
.collapsed .menu-link:active {
    color : black  !important;
    padding-left: 2px !important;
}
.collapsed .menu-link i {
    color : black  !important;
    margin-right : 15px !important ;
}
.collapsed:hover {
   width: 250px !important ;
   position : absolute ; 
   z-index : 11111 ;
   height : 100vh
}
.collapsed:hover .link-title{
    display : block ;
}
.equals-sign{
    font-size: 35px;
    width : "30px" ;
}
@media (max-width : 425px) {
    .collapsed{
        width: 50px !important;
    }
}